<template>
  <div class="min-h-full w-full bg-gray-50">
     
         <AppLayout>
            <router-view></router-view>
            <!-- <router-view v-slot="{ Component, route }">
              <transition enter-active-class="transition ease-out duration-300" enter-from-class="transform opacity-100 translate-y-8" enter-to-class="transform opacity-100 translate-y-0" leave-active-class="transition ease-in duration-300" leave-from-class="transform opacity-100 translate-y-0" leave-to-class="transform opacity-90 translate-y-8">
                          <component :is="Component" :key="route.path"/>
              </transition>
          </router-view> -->
        </AppLayout>

 
  </div>
</template>
<script>
import AppLayout from '@/components/Layout.vue'
export default {
  components:{
    AppLayout
  },
  // watch:{
  //   authUser(val){
  //     console.log(val)
  //     if(val==null){
  //       this.$router.push('signin')
  //     }
  //   }
  // },
  computed:{
    authUser(){
      return this.$store.getters.authUser
    }
  }
}
</script>
<style>
</style>
