import { createStore } from 'vuex'

export default createStore({
    state: {
        // user: {
        //     name: 'Tomek',
        //     email: 'tomek@savvy.com',
        // },
        user: null,
        userBalance: {
            balance: 1314.7,
            tickets: 1414
        },
        bankList: [
            { bank: 'HSBC', name: 'Tomek', type: 'Account', number: '4556734775755246' },
            { bank: 'Santander Bank', name: 'Tomek', type: 'Visa', number: '7765734775755221' },
        ],
        accountActionsList: [{
                date: '01-16-2022',
                actions: [
                    { title: 'Prize', type: 'prize', value: 300, ticket: 'A3D676' },
                    { title: 'Interest', type: 'interest', value: 1.13, ticket: null },
                ]
            },
            {
                date: '01-14-2022',
                actions: [
                    { title: 'Deposit', type: 'deposit', value: 1000, ticket: null },
                ]
            },
            {
                date: '01-11-2022',
                actions: [
                    { title: 'Tom Hanks referred', type: 'reffer', value: 100, ticket: null },
                ]
            },
            {
                date: '01-09-2022',
                actions: [
                    { title: 'Prize', type: 'prize', value: 2.5, ticket: 'A3D221' },
                    { title: 'Interest', type: 'interest', value: 1.07, ticket: null },
                ]
            },
            {
                date: '01-06-2022',
                actions: [
                    { title: 'Standing order', type: 'sorder', value: 300, ticket: null },
                ]
            },
            {
                date: '01-05-2022',
                actions: [
                    { title: 'Withdrawal', type: 'withdraw', value: 300, ticket: null },
                ]
            },
            {
                date: '01-02-2022',
                actions: [
                    { title: 'Prize', type: 'prize', value: 10, ticket: 'A3D221' },
                ]
            },
        ],
        prizeDrawed: false,
        prize: 0,
        lastDraw: 300,
        depositTab: 'Deposit'
    },
    mutations: {
        addDeposit(state, payload) {
            state.userBalance = {
                balance: state.userBalance.balance = parseFloat(state.userBalance.balance) + parseFloat(payload.deposit),
                tickets: state.userBalance.tickets = parseFloat(state.userBalance.tickets) + parseFloat(payload.tickets),
            }
        },
        withdraw(state, payload) {
            state.userBalance = {
                balance: state.userBalance.balance = parseFloat(state.userBalance.balance) - parseFloat(payload.withdraw),
                tickets: state.userBalance.tickets = parseFloat(state.userBalance.tickets) - parseFloat(payload.tickets),
            }
        },
        addItemToActionList(state, payload) {
            //prepare action model
            let action = null
            switch (payload.type) {
                case 'deposit':
                    action = { title: 'Deposit', type: payload.type, value: payload.value, ticket: 'A3' + Math.floor(100 + Math.random() * 900).toString(4) }
                    break
                case 'withdraw':
                    action = { title: 'Withdrawal', type: payload.type, value: payload.value, ticket: null }
                    break
                case 'interest':
                    action = { title: 'Interest', type: payload.type, value: payload.value, ticket: null }
                    break
                case 'prize':
                    action = { title: 'Prize', type: payload.type, value: payload.value, ticket: null }
                    break
                case 'reffer':
                    action = { title: truncate(payload.friend, 12) + ' referred', type: payload.type, value: payload.value, ticket: null }
                    break
            }

            //checking if add to existing date or create new one
            let today = new Date().toDateString()
            if (state.accountActionsList.length > 0 && today == new Date(state.accountActionsList[0].date).toDateString()) {
                state.accountActionsList[0].actions.unshift(action)
            } else {
                state.accountActionsList.unshift({ date: new Date().toDateString(), actions: [action] })
            }


        },
        setPrizeDrawed(state, payload) {
            state.prizeDrawed = payload
        },
        setPrize(state, payload) {
            state.prize = payload
        },
        signOut(state) {
            state.user = null
        },
        signIn(state, payload) {
            console.log('login user:')
            state.user = payload.user
        },
        setDepositTab(state, payload) {
            state.depositTab = payload
        },
        addAccountToBankList(state, payload) {
            console.log(payload)
            let account = Object.assign({}, payload.account)
            let newList = [...state.bankList];
            newList.push(account)
            state.bankList = newList
        },
        setBalance(state, payload) {
            state.userBalance = payload.userBalance
        },
        setBankList(state, payload) {
            state.bankList = payload.bankList
        },
        setActionList(state, payload) {
            state.accountActionsList = payload.accountActionsList
        }
    },
    actions: {
        newBankList({ commit }, payload) {
            console.log(payload)
            return commit('addAccountToBankList', payload)
        },
        signInUser({ commit }, payload) {
            console.log(payload.form)
                //backend call
                //if auth
            commit('signIn', { user: { name: 'Tomek', lastname: 'Savvy', email: 'tomek@besavvy.com' } })

        },
        signOutUser({ commit }) {
            commit('signOut')
        },

        signUpUser({ commit }, payload) {
            console.log(payload.form)
                //backend call
                //if auth 
            commit('setBalance', { userBalance: { balance: 0, tickets: 0 } })
            commit('setBankList', { bankList: [] })
            commit('signIn', { user: { name: payload.form.name, lastname: payload.form.lastname, email: payload.form.email } })
            commit('setActionList', { accountActionsList: [] })

        },


    },
    modules: {},
    getters: {
        authUser(state) {
            return state.user
        }
    }


})

function truncate(str, n) {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
}