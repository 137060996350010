<template>
  <div class="min-h-full bg-gray-100 overflow-hidden  w-full">
  <div v-if="user==null" class="min-h-screen">
    <slot></slot>
  </div>
  <div v-else class="min-h-full w-full">
    <div class="bg-gray-50">
      <header class="pt-1 pb-2 px-2" id="topbar-balance" :class="topbarStyle">
        <div class="max-w-7xl mx-auto  sm:px-4 lg:px-6" >
          <topbar-balance class="max-w-lg m-auto"/>
        </div>
      </header>
      <div id="pic-wrapper" :style="picWrapperStyle" class="bg-gradient-to-b from-white to-gray-100 relative">
     
          <invite v-if="this.$route.name=='invite'" class="top-0 w-full ml-0 fixed bg-gradient-to-b from-white to-gray-100 left-0 z-0" />
    
      
          <deposit v-if="this.$route.name=='deposit' && $store.state.depositTab=='Deposit'" class="w-full ml-0 pb-9  bg-gradient-to-b from-white to-gray-100 left-0 z-0" />
        
      
          <withdraw v-if="this.$route.name=='deposit' && $store.state.depositTab=='Withdraw'" class="w-full ml-0  pb-10 bg-gradient-to-b from-white to-gray-100 left-0 z-0" />
        
      
          <div class="w-full bg-gradient-to-b from-white to-gray-100">
            <div v-if="this.$route.name=='account'" class="w-full max-w-7xl left-0 -top-0   mt-2 p-0.5">
                  <div class="grid grid-cols-2 gap-1 justify-around mx-1 px-2 pt-2 pb-6 rounded-xl z-10" style="background:#ECFBFB">
                  <div class="flex flex-col justify-center">
                    <span class="font-normal text-gray-500 m-auto">Balance</span>
                  <div class="text-center flex justify-center items-center p-0 text-gray-600">
                      <!-- <EuroIcon class="w-6 h-6  rounded-full text-gray-500" :fill="'#fff'"/> -->
                      <p class="text-gray-700 z-10 font-semibold text-3xl ml-1"><span class="font-normal">€</span> {{euros}}<span class="text-xl">.{{cents}}</span></p>
                  </div>
                  </div>

                  <div class="flex flex-col justify-center">
                    <span class="font-normal text-gray-500 m-auto">Tickets</span>
                      <div class="flex justify-center items-center m-auto">
                          <TicketIcon class="w-6 z-10 h-6 mt-1 rounded-full text-gray-700" :fill="'#0a0a0a'"/>
                          <p class="text-gray-700 z-10 font-semibold text-3xl ml-2">{{tickets}}</p>
                      </div>
                  </div>
              </div>         
            </div>
            <account v-if="this.$route.name=='account'" class="w-full pb-2 ml-0 pb-0 left-0 -mt-24 z-10" />
          </div>
        
      </div>
    </div>

    <main class="max-w-lg m-auto z-20">
      <div class="max-w-7xl mx-auto pb-4 px-1 bg-gray-100 sm:px-6 lg:px-8">
        <div>
          <div id="components-wraper" class="bg-white rounded-t-3xl py-4" 
          :style="pageWrapperInlineStyle">
            <transition mode="in-out" enter-active-class="transition ease-out duration-300" enter-from-class="transform opacity-100 translate-y-8" enter-to-class="transform opacity-100 translate-y-0" leave-active-class="transition ease-in duration-300" leave-from-class="transform opacity-100 translate-y-0" leave-to-class="transform opacity-90 translate-y-8">
                           <slot></slot>
              </transition>
           
          </div>
        </div>
      </div>
    </main>
     <footer class="fixed bottom-0 w-full bg-gray-50" id="app-foot">
      <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div class="pb-2 text-sm text-gray-500 text-center sm:text-left">
         <foot-navigation/>
        </div>
      </div>
    </footer>
  </div>
</div>
</template>
<script>

import FootNavigation from '@/components/FootNavigation.vue'
import TopbarBalance from '@/components/TopbarBalance.vue'
import Invite from '@/assets/invite.vue'
import Deposit from '@/assets/deposit.vue'
import Withdraw from '@/assets/withdraw.vue'
import Account from '@/assets/account.vue'
import TicketIcon from '@/assets/icons/ticket.vue'


export default {
  components: {
    FootNavigation,
    TopbarBalance,
    Invite,
    Deposit,
    Withdraw,
    Account,
    TicketIcon

  },

  computed:{
    user(){
      return this.$store.getters.authUser
    },
    invitePage(){
      return this.$route.name=='invite'
    },
    accountPage(){
      return this.$route.name=='account'
    },
    prizePage(){
      return this.$route.name=='prize'
    },
    pageWrapperInlineStyle(){
      if(this.prizePage) return 'height:calc(100vh - 130px); margin-top:10px; overflow: scroll'
      else if(this.accountPage) return 'height:calc(100vh - 320px);  overflow: scroll;'
      else return 'height:calc(100vh - 290px); overflow: scroll'
      
     // else return 'height:calc(100vh - 150px); margin-top:10px'
    },

    picWrapperStyle(){
      if(this.prizePage) return 'height:0px;'
      else if(this.accountPage) return 'height:200px;'
      else return 'height:180px;'
    },

    topbarStyle(){
      if(this.prizePage) return 'bg-gradient-to-b from-white to-gray-100'
      else return 'bg-white'
    },
    balance(){
        return this.numberWithCommas(parseFloat(this.$store.state.userBalance.balance).toFixed(2))
        },
        euros(){
          return this.balance.slice(0,-3)
        },
        cents(){
          return this.balance.slice(this.balance.length-2,this.balance.length)
        },
        tickets(){
        return this.numberWithCommas(parseFloat(this.$store.state.userBalance.tickets))
        },

  },
}
</script>
<style>
.fade-up-enter-active, .fade-up-leave-active {
  transition: all 0.5s ease-in-out;
  transform: translateY(0px);
}
.fade-up-enter{
  /* transition-delay: 0.5s; */
  transition-duration: 1s;
  opacity: 0;
  transform: translateY(50px);
}

.fade-up-leave-to{
  opacity: 0;
  transform: translateY(50px);
}

#topbar-balance
{
  height: 70px;
}

#components-wraper{
  /* height: calc(100vh - 150px); */
  overflow: scroll;
}

#app-foot{
  height: 50px;
  overflow: hidden;
}

#pic-wrapper{
  overflow:hidden;
}


body{
  overflow: hidden;
  min-height: 100vh;
}
#app{
  min-height: 100vh;
}

.fade-enter-active, .fade-leave-active {
  transition: all ease-in-out .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-down-enter-active, .fade-up-leave-active {
  transition: all 0.5s ease-in-out;
  transform: translateY(0px);
}
.fade-down-enter{
  /* transition-delay: 0.5s; */
  transition-duration: 1s;
  opacity: 0;
  transform: translateY(-50px);
}

.fade-down-leave-to{
  opacity: 0;
  transform: translateY(-50px);
}


</style>
