<template>
    <div class="flex justify-between">
        <Disclosure as="nav" >
        <div class="mx-auto ">
          <div class="relative h-16 flex items-center ">
            <div class="block ">
              <div class="flex items-center">
                <!-- Profile dropdown -->
                <Menu as="div" class="relative flex-shrink-0">
                  <div>
                    <MenuButton class="bg-green-500 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-600 focus:ring-white">
                      <span class="sr-only">Open user menu</span>
                      <div class="rounded-xl h-12 w-12 flex justify-center items-center">
                        <UserIcon class="w-8 h-8" :fill="'#fff'"/>
                      </div>
            
                    </MenuButton>
                  </div>
                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems class="origin-top-right z-20 absolute left-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                        <a :href="item.href" :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">
                          {{ item.name }}
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a href="#" @click="signOut()" :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">
                          Sign out
                        </a>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </div>
            <div class="ml-2 flex items-start justify-center flex-col">
              <p class="text-lg font-bold text-gray-900">Hey {{user.name}}!</p>
              <span class="text-sm text-gray-400">{{new Date().toLocaleDateString()}}</span>
            </div>
          </div>
        </div>
      </Disclosure>
        <transition name="fade" mode="in-out">
            <div class="flex items-end justify-center  flex-col" v-if="!accountPage">
             
                 <p class="text-lg text-right font-bold text-gray-900">€ {{euros}}<span class="text-xs font-bold">.{{cents}}</span></p>
                  <span class="text-lg text-right font-bold text-gray-900">{{tickets}}<span class="font-normal text-xs"> tickets</span></span>
            
            </div>
        </transition>

<!-- 
    <div class="grid grid-cols-2 w-full grid-flow-col gap-0 mt-2 bg-white rounded-3xl">
            <div class="text-center  rounded-xl p-1 text-gray-600">
                <div class="flex items-center justify-center m-auto">
                    <WalletIcon class="w-5 h-5 p-0.5 bg-white rounded-full text-gray-500" :fill="'#0a0a0a'"/>
                    <span class="font-normal ml-1">Balance</span>
                </div>
                <p class="text-xl">€{{balance}}</p>
            </div>
            <div class="text-center  rounded-xl p-1 text-gray-600">
                <div class="flex justify-center items-center m-auto">
                    <TicketIcon class="w-5 h-5 p-0.5 bg-white rounded-full text-gray-500" :fill="'#0a0a0a'"/>
                    <span class="font-normal ml-1">Tickets</span>
                </div>
                <p class="text-xl">{{tickets}}</p>
            </div>
    </div> -->

    <!-- <div v-if="accountPage" class="fixed  w-full left-0 top-20  mt-2 p-0.5">
            <div class="grid grid-cols-2 gap-1 justify-around mx-10 rounded-xl">
          
            <div class="flex flex-col justify-center">
              <span class="font-normal text-gray-700 m-auto">Balance</span>
            <div class="text-center flex justify-center items-center rounded-xl p-0 text-gray-600">
  
                <p class="text-gray-700 font-semibold text-xl ml-1">€{{balance}}</p>
            </div>
            </div>

            
            <div class="flex flex-col justify-center">
              <span class="font-normal text-gray-700 m-auto">Tickets</span>
                <div class="flex justify-center items-center m-auto">
               
                    <p class="text-gray-700 font-semibold text-xl ml-1">{{tickets}}</p>
                </div>
            </div>
        
        </div>     
            
    </div> -->
      <!-- <transition-group name="fade" mode="out-in">
         <invite v-if="this.$route.name=='invite'" class="-top-2 w-full ml-1 fixed bg-gradient-to-b from-white to-gray-100 left-0 z-10" />
         <deposit v-if="this.$route.name=='deposit' && $store.state.depositTab=='Deposit'" class="top-20 w-full ml-0 fixed bg-gradient-to-b from-white to-gray-100 left-0 z-10" />
        <withdraw v-if="this.$route.name=='deposit' && $store.state.depositTab=='Withdraw'" class="top-16 w-full ml-0 fixed bg-gradient-to-b from-white to-gray-100 left-0 z-10" />
      </transition-group> -->

       </div>
</template>
<script>
import { Disclosure, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
// import EuroIcon from '@/assets/icons/euro.vue'
// import TicketIcon from '@/assets/icons/ticket.vue'
import UserIcon from '@/assets/icons/user-outline.vue'


const userNavigation = [
  { name: 'Settings', href: '#' },
]

export default{
    components:{
        // EuroIcon,
        // TicketIcon,
        Disclosure,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        UserIcon,

    },

      setup() {
        return {
        userNavigation,
        }
    },
    
    computed:{
        balance(){
        return this.numberWithCommas(parseFloat(this.$store.state.userBalance.balance).toFixed(2))
        },
        euros(){
          return this.balance.slice(0,-3)
        },
        cents(){
          return this.balance.slice(this.balance.length-2,this.balance.length)
        },
        tickets(){
        return this.numberWithCommas(parseFloat(this.$store.state.userBalance.tickets))
        },
        user(){
        return this.$store.getters.authUser
        },
        accountPage(){
          return this.$route.name=='account'
        },
    },
    methods:{
        signOut(){
          this.$store.dispatch('signOutUser').then(()=>{
            console.log(this.$store.state.user)
            this.$router.push('signin')
            console.log(this.$store.state.user)
          })
        }
      }

}
</script>
<style>
</style>