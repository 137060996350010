<template>
 <div>
    <div>
      <div class=" bg-gray-50  border-gray-200 flex justify-center">
        <nav class="-mb-px flex space-x-1" aria-label="Tabs">
            <router-link v-for="tab in tabs" :key="tab.name" :to="tab.href" :class="[activePage(tab.slug) ? 'border-green-600 text-green-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'group flex flex-col justify-between items-center content-center items-center py-1 px-1 border-t-2 font-medium text-sm']"> 
              <component :is="tab.icon" :fill="activePage(tab.slug) ? '#4CB8C2' : '#9ca3af'" :class="[activePage(tab.slug) ? 'text-green-600' : 'text-gray-400 group-hover:text-gray-500', 'mx-5 h-6 w-6']" aria-hidden="true" />
              <!-- <span class="text-sm ">{{ tab.name }}</span> -->
            </router-link>
               <!-- <router-link to="/deposit">Deposit</router-link> |
      <router-link to="/account">Account</router-link>
      <router-link to="/prize">Prize draw</router-link>
      <router-link to="/invite">Invite a friend</router-link> -->
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import WalletIcon from '@/assets/icons/wallet-outline.vue'
import ValutIcon from '@/assets/icons/valut-outline.vue'
import StarIcon from '@/assets/icons/star-outline.vue'
import PersonAddIcon from '@/assets/icons/person-add-outline.vue'

const tabs = [
  { name: 'Deposit', href: '/deposit', icon: ValutIcon, slug:'deposit' },
  { name: 'Account', href: '/account', icon: WalletIcon, slug:'account' },
  { name: 'Prize draw', href: '/prize', icon: StarIcon, slug:'prize'},
  { name: 'Invite a friend', href: '/invite', icon: PersonAddIcon, slug:'invite' },
]
export default {
   setup() {
    return {
      tabs,
    }
  },
  methods:{
    activePage(name){
      if(this.$route.name == name) return true
      return false
    }
  }
}
</script>