// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [{
        path: '',
        redirect: '/deposit'
    },
    {
        path: '/deposit',
        name: 'deposit',
        component: () =>
            import ('@/views/DepositPage.vue'),

    },
    {
        path: '/account',
        name: 'account',
        component: () =>
            import ('@/views/AccountPage.vue')
    },
    {
        path: '/prize',
        name: 'prize',
        component: () =>
            import ('@/views/PrizePage.vue')
    },
    {
        path: '/invite',
        name: 'invite',
        component: () =>
            import ('@/views/InvitePage.vue')
    },

    {
        path: '/signin',
        name: 'signin',
        component: () =>
            import ('@/views/SignInPage.vue')
    },

    {
        path: '/signup',
        name: 'signup',
        component: () =>
            import ('@/views/SignUpPage.vue')
    }


]

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes
})

export default router