import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'

const myMixin = {

    methods: {
        numberWithCommas(number) {
            var parts = number.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        },
        bankNumber(number) {
            let string = number.toString() + ''
            return '*' + string.slice(string.length - 4)
        }
    }
}

router.beforeEach((to, from, next) => {
    // console.log(store.state.user)
    if ((to.name != 'signin' && to.name != 'signup') && store.state.user === null) next({ path: '/signin' })
    else if ((to.name == 'signin' || to.name == 'signup') && store.state.user !== null) next({ path: '/deposit' })
    else next()
        // next()
})

createApp(App).use(store).use(router).mixin(myMixin).mount('#app')